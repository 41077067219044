import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import logo from 'assets/SU-LOGOwhite-small.png'
import { Facebook, Instagram } from '@mui/icons-material'
import './index.css'

const Footer = () => {
  return (
    <Box
      component={'footer'}
      sx={{
        backgroundColor: 'neutral.dark',
        color: '#fff',
        px: { xs: 3, md: 20 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          width: '100%',
          pt: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            mb: { xs: 3, md: 0 },
            flex: { md: 3 },
          }}
        >
          <Typography variant="h6" component="div" sx={{ mb: 3 }}>
            For more help, please send us an email on su@aucegypt.edu or contact us through any of
            our social media platforms:
          </Typography>
          {[
            { title: 'auc.su', link: 'https://www.instagram.com/auc.su', Icon: Instagram },
            { title: 'auc.su', link: 'https://www.facebook.com/auc.su/', Icon: Facebook },
            { title: 'SU Rep', link: 'https://www.facebook.com/AUC.SU.REP', Icon: Facebook },
          ].map(item => (
            <Box
              key={item.title}
              component={'a'}
              href={item.link}
              sx={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                color: '#fff',
                ':hover': {
                  color: 'primary.main',
                },
                mt: 2,
              }}
            >
              <item.Icon />
              <Typography variant="h6" component="div" sx={{ ml: 2, mr: 2 }}>
                {item.title}
              </Typography>
            </Box>
          ))}
          <Typography
            variant="h4"
            component="div"
            sx={{ mt: 3, fontSize: { xs: '18px', md: '24px' } }}
          >
            #ReBuilding
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            overflow: 'hidden',
            resize: 'contain',
            alignItems: 'center',
            justifyContent: { xs: 'center', md: 'flex-end' },
            mr: { md: 3 },
            mt: { xs: 3, md: 2 },
            flex: { md: 1 },
          }}
        >
          <img
            src={logo}
            id="logo"
            alt="logo"
            style={{
              width: '20%',
              // height: '60%',
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          width: '100%',
          mt: { xs: 0, md: 1 },
          mb: 3,
        }}
      >
        <Typography variant="h6" component="div" sx={{ mt: 2 }}>
          {'\u00A9'} 2024 Student Union, All Rights Reserved
        </Typography>
        <Typography variant="h6" component="div" sx={{ mt: 2 }}>
          Version 1.0.4
        </Typography>
      </Box>
    </Box>
  )
}

export default Footer
