const routes = {
  HOME: '/',

  EVENTS: '/events',

  LOCKERS: '/lockers',

  RECRUITMENT_FORM: '/recruitment/form',

  SU_CARD: '/su-card',

  STUDY_NOTES: '/study-notes',
  CATEGORY_LIST: '/study-notes/CategoryList',
  COURSE_LIST: '/study-notes/:category',
  NOTE_LIST: '/study-notes/:category/:course',
  UPLOAD_NOTE: '/study-notes/:category/UploadNote',
  // TRAINING: '/training',

  //REP_REPORT: '/representation/case-report',
  COURSE_TRACKER: '/course_tracker',
  DELETE_ACCOUNT: '/delete-account',
}

export default routes
