import React from 'react'
// import { useEffect } from 'react'
import { Button, Box } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import { useAuth } from 'context'
import { useMutation } from 'react-query'
import { useToast } from 'context'
import CardWithShadow from 'components/common/card/CardWithShadow'
import { Typography } from '@mui/material'
import { LoginOutlined } from '@mui/icons-material'
import { getLockers, postLocker, checkLocker } from 'api/lockers.service'
import { useNavigate } from 'react-router-dom'

import './styles.css'

const Lockers = () => {
  const { user, signIn } = useAuth()
  const { success, error } = useToast()
  const navigate = useNavigate()

  const [location, setLocation] = React.useState('')
  const [lockers, setLockers] = React.useState([])
  const [lockersList, setLockersList] = React.useState([])
  const [submitted, setSubmitted] = React.useState(false)
  const [lockersAvailable, setLockersAvailable] = React.useState(true)

  const SignInButton = ({ OnSignIn }) => (
    <Button
      sx={{ backgroundColor: '#fff' }}
      size="large"
      onClick={OnSignIn}
      variant="outlined"
      color="primary"
      startIcon={<LoginOutlined />}
    >
      Sign In
    </Button>
  )

  const getLockersApi = useMutation(getLockers, {
    onSuccess: data => {
      setLockers(data.data)
    },
    onError: error => {
      console.log(error)
    },
  })

  function getLockersFunction() {
    getLockersApi.mutate()
  }

  const postLockerApi = useMutation(postLocker, {
    onSuccess: data => {
      success('Locker assigned successfully!')
    },
    onError: error => {
      error('Locker assignment failed!')
    },
  })

  function postLockerFunction() {
    postLockerApi.mutateAsync({
      location: location,
      gid: user.google_id,
    })
  }

  //check if the user already has a locker.
  const checkLockerApi = useMutation(checkLocker, {
    onSuccess: data => {
      if (data.data.data.length > 0) {
        setSubmitted(true)
      } else {
        setSubmitted(false)
      }
    },
    onError: error => {
      console.log(error)
    },
  })

  function checkLockerFunction() {
    checkLockerApi.mutateAsync(user.google_id)
  }

  //first thing to do when loading the page, load the lockers and check if the user already has a locker.
  React.useEffect(() => {
    getLockersFunction()
    if (user !== null) {
      checkLockerFunction()
    }
  }, [])

  React.useEffect(() => {
    if (lockers && lockers.data) {
      if (lockers.data.length === 0) {
        setLockersAvailable(false)
      } else {
        const data = lockers.data
        const lockersList = data.map(locker => (
          <MenuItem key={locker.name} value={locker.name}>
            {locker.name}
          </MenuItem>
        ))
        setLockersList(lockersList)
      }
    }
  }, [lockers])

  const handleLocationChange = event => {
    setLocation(event.target.value)
  }

  const handleSubmit = event => {
    event.preventDefault()
    postLockerFunction()
    navigate('/')
  }

  if (user === null) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          height: '100%',
          width: '100%',
        }}
      >
        <CardWithShadow
          sx={{
            flex: 1,
            flexDirection: 'column',
            p: 10,
            display: 'flex',
            maxWidth: '80%',
            maxHeight: '80%',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h1"
            component="div"
            sx={{
              // flexGrow: 1,
              flex: 0.3,
              fontSize: { xs: 25, sm: 36 },
              fontWeight: 700,
            }}
          >
            Please sign in to access the form.
          </Typography>
          <SignInButton OnSignIn={signIn} />
        </CardWithShadow>
      </Box>
    )
  } else if (!submitted && lockersAvailable) {
    return (
      <div id="body">
        <form onSubmit={handleSubmit} id="form">
          <FormControl fullWidth sx={{ mb: 4 }}>
            <InputLabel id="locationLabel">Location</InputLabel>
            <Select
              labelId="locationLabel"
              id="location"
              value={location}
              label="Location"
              onChange={handleLocationChange}
              fullWidth
              required
            >
              {lockersList}
            </Select>
            <Button id="submit" variant="outlined" color="secondary" type="submit">
              Book Locker
            </Button>
          </FormControl>
        </form>
      </div>
    )
  } else if (submitted) {
    return (
      <div id="body">
        <Typography
          variant="h1"
          component="div"
          sx={{ flexGrow: 1, fontSize: { xs: 25, sm: 36 }, fontWeight: 700, mt: 4, ml: 4 }}
        >
          You have a locker! Kindly collect your locker key from the help desk.
        </Typography>
      </div>
    )
  } else if (!lockersAvailable) {
    return (
      <div id="body">
        <Typography
          variant="h1"
          component="div"
          sx={{ flexGrow: 1, fontSize: { xs: 25, sm: 36 }, fontWeight: 700, mt: 4, ml: 4 }}
        >
          No lockers available!
        </Typography>
      </div>
    )
  }
}

export default Lockers
