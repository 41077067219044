import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import routes from 'routing/routes'

import Home from 'pages/home'
import EventsHome from 'pages/events'
import Lockers from 'pages/lockers'
import SUCardHome from 'pages/su-card'
import StudyNotesHome from 'pages/study-notes'
import RecruitmentForm from 'pages/recruitment/form'
import CategoryList from 'pages/study-notes/CategoryList'
import CourseList from 'pages/study-notes/[category]'
import NoteList from 'pages/study-notes/[course]'
import UploadNote from 'pages/study-notes/UploadNote'
import Training from 'pages/training'
import CourseTracker from 'pages/course_tracker'
import DeleteAccount from 'pages/delete-account'

const BrowserRouter = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { path: routes.HOME, element: <Home />, index: true },
      { path: routes.EVENTS, element: <EventsHome /> },
      { path: routes.LOCKERS, element: <Lockers /> },
      { path: routes.RECRUITMENT_FORM, element: <RecruitmentForm /> },
      { path: routes.SU_CARD, element: <SUCardHome /> },
      { path: routes.STUDY_NOTES, element: <StudyNotesHome /> },
      { path: routes.COURSE_TRACKER, element: <CourseTracker /> },
      { path: routes.CATEGORY_LIST, element: <CategoryList /> },
      { path: routes.COURSE_LIST, element: <CourseList /> },
      { path: routes.NOTE_LIST, element: <NoteList /> },
      { path: routes.UPLOAD_NOTE, element: <UploadNote /> },
      { path: routes.DELETE_ACCOUNT, element: <DeleteAccount /> },
      // { path: routes.TRAINING, element: <Training /> },
      { path: '*', element: <h1>404</h1> },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <RouterProvider router={BrowserRouter} />

  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
