import React from 'react'
import {
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Toolbar,
  AppBar,
  Tooltip,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material'
import { LoginOutlined, AccountCircle, Menu as MenuIcon } from '@mui/icons-material'
import logo from 'assets/SU-LOGOwhite-small.png'
import { useAuth } from 'context'
import styled from '@emotion/styled'
import StyledNavLink from './StyledNavLink'
import navlinkItems from './navlinkItems'
import './index.css'
import Loading from 'components/common/loading'

const SignInButton = ({ OnSignIn }) => (
  <Button
    variant="contained"
    sx={{ backgroundColor: '#fff' }}
    size="large"
    onClick={OnSignIn}
    startIcon={<LoginOutlined />}
  >
    Sign In
  </Button>
)

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: '3rem',
    '& .MuiMenuItem-root': {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.neutral.light,
      },
    },
  },
}))

const AccountAvatar = ({ user, OnSignOut }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return (
    <>
      <Tooltip title={'Account'}>
        {user?.picture ? (
          <Avatar src={user.picture} onClick={handleClick} />
        ) : (
          <IconButton size="large" edge="end" color="inherit" onClick={handleClick}>
            <AccountCircle fontSize="large" />
          </IconButton>
        )}
      </Tooltip>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem disabled>{user?.given_name + ' ' + user?.family_name}</MenuItem>
        <MenuItem
          onClick={() => {
            OnSignOut()
            handleClose()
          }}
        >
          Logout
        </MenuItem>
      </StyledMenu>
    </>
  )
}

export default function Topbar() {
  const { user, signIn, signOut, loading } = useAuth()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen)

  return (
    <Box>
      {loading && <Loading />}
      <AppBar
        position="static"
        sx={{
          color: '#FFF',
          backgroundColor: '#000',
          justifyContent: { md: 'center' },
          height: { md: '20vh' },
        }}
      >
        <Container maxWidth="xl">
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: { md: '0 2rem', xs: '1rem 1rem' },
            }}
            disableGutters
          >
            <img id="top-logo" src={logo} alt="logo" />

            <Box
              component={'nav'}
              sx={{
                flex: 0.75,
                display: { md: 'flex', xs: 'none' },
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {navlinkItems.map(item => (
                <StyledNavLink
                  to={item.to}
                  key={item.name}
                  end={item.name === 'Home'}
                  className={'nav-link'}
                >
                  {item.name}
                </StyledNavLink>
              ))}
              {user ? (
                <AccountAvatar user={user} OnSignOut={signOut} />
              ) : (
                <SignInButton OnSignIn={signIn} />
              )}
            </Box>

            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              <MenuIcon id="menuicon" />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} anchor="right">
        <List>
          {navlinkItems.map(item => (
            <ListItem button key={item.name} component={StyledNavLink} to={item.to}>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
          {user ? (
            <ListItem button onClick={signOut}>
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          ) : (
            <ListItem button onClick={signIn}>
              <ListItemIcon>
                <LoginOutlined />
              </ListItemIcon>
              <ListItemText primary="Sign In" />
            </ListItem>
          )}
        </List>
      </Drawer>
    </Box>
  )
}
